import { useAuth0 } from "../services/Auth0/react-auth0-wrapper";
import React, { useState, useEffect } from "react";
import UserModel from "models/UserModel";

export const AprannAuthContext = React.createContext();
export const useAprannAuthUser = () => React.useContext(AprannAuthContext);
export const ROLE_NAMESPACE = process.env.REACT_APP_ROLE_NAMESPACE;

export const AprannAuthProvider = props => {
	const [aprannAuthUser, setAprannAuthUser] = useState();
	const [newUser, setNewUser] = useState(false);
	const [loading, setLoading] = useState(true);

	/**
	 * Auth0 attributes
	 */
	const {
		user: auth0User,
		loading: auth0Loading, //auth0 code module is loaded and ready to use
		isAuthenticated,
		getTokenSilently,
		logout,
		loginWithRedirect,
		loginWithPopup
	} = useAuth0();

	const isAuthLoaded = () => {
		return isAuthenticated && auth0User;
	};

	useEffect(() => {
		if (isAuthLoaded()) {
			setLoading(true);
			getTokenSilently().then(accessToken =>
				fetch(process.env.REACT_APP_API_URL + "users/getByToken", {
					method: "GET",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization: `Bearer ${accessToken}`
					}
				})
					.then(res => {
						if (res.ok) return res.json();
						else if (res.status == 404) {
							setNewUser(true);

							return false;
						} else throw new Error("Could not load user");
					})
					.then(data => {
						//if data = false, it should mean it's a new user, and we don't want to do anything
						if (data) {
							const aUser = new UserModel(data);
							/*Add info from the auth0 user object to our aprann user class */
							aUser.setRoles(auth0User[ROLE_NAMESPACE]);
							aUser.setTokenInfo(auth0User);
							setAprannAuthUser(aUser);
							setLoading(false);
						}
					})
					.catch(error => {
						console.log(`Error loading user ${error}`);
					})
			);
		} else if (!auth0Loading) {
			//auth0 is done loading, not user is logged in to authenticate
			setLoading(false);
		}
	}, [auth0Loading]);

	return (
		<AprannAuthContext.Provider
			value={{
				auth0User,
				loading,
				isAuthenticated,
				aprannAuthUser, //deprecated in favor of user below
				user: aprannAuthUser,
				getTokenSilently,
				logout,
				newUser,
				loginWithRedirect,
				loginWithPopup,
				isAuthLoaded
			}}
		>
			{props.children}
		</AprannAuthContext.Provider>
	);
};
