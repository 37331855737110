import { SET_PARTNER, GET_PARTNER } from "actions/index";
import PartnerModel from "models/PartnerModel";

const initState = {
	contextPartner: new PartnerModel({})
};

const PartnerReducer = (state = initState, action) => {
	if (action.type === SET_PARTNER) {
		return {
			...state,
			contextPartner: action.payload
		};
	} else {
		return state;
	}
};

export default PartnerReducer;
