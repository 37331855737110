import { ADD_TO_CART, REMOVE_ITEM, EMPTY_CART } from "actions/CartActions";
import StorageContainer from "helpers/StorageContainer";

const storageContainer = new StorageContainer("cart");
const initState = { items: storageContainer.getStoredContainer() };

const CartReducer = (state = initState, action) => {
	if (action.type === ADD_TO_CART) {
		let cartItem = action.payload;
		storageContainer.addObject(cartItem.id, cartItem);
		return {
			...state,
			items: storageContainer.getStoredContainer()
		};
	}

	if (action.type === REMOVE_ITEM) {
		storageContainer.removeObject(action.payload);
		return {
			...state,
			items: storageContainer.getStoredContainer()
		};
	}

	if (action.type === EMPTY_CART) {
		Object.keys(state.items).forEach(itemId => storageContainer.removeObject(itemId));
		return {
			...state,
			items: {}
		};
	} else {
		return state;
	}
};

export default CartReducer;
