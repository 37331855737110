import React from "react";
import { showReportDialog } from "@sentry/browser";
import logo from "../components/SplashScreen/img/aprann-logo.png";

interface ebProps {
	fallback: any;
}

interface ebState {
	hasError: boolean;
}

export const ErrorBoundaryFallback = ({ error, componentStack, resetError, eventId }) => {
	return (
		<>
			<div className="d-flex align-items-center justify-content-center vh-100 container">
				<div className="text-center">
					<img src={logo} alt="500" className="img-fluid" width="200" />
					<h1>Our apologies!</h1>
					<p className="fs-3">
						<span className="text-danger">The application has encountered an error.</span>
					</p>
					<div>
						The error has been automatically reported. If you have additional information you'd like to provide, please email{" "}
						<a href={`mailto:support@aprann.org?subject=Error:${eventId}`}>support@aprann.org</a>. and reference the Event ID
						provided below.
					</div>
					<div className="mt-3">
						<strong>Event ID: </strong>
						{eventId}
					</div>
					<div>
						<strong>Error:</strong> {error.toString()}
					</div>

					<div className="mt-3">
						{/* <button className="btn btn-outline-secondary mr-2" onClick={() => showReportDialog({ eventId })}>
							Report feedback
						</button> */}
						<a href="/" className="btn btn-primary">
							Return Home
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default class ErrorBoundary extends React.Component<ebProps, ebState> {
	constructor(props: ebProps) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	logErrorService(error: any, errorInfo: any) {
		console.log("ErrorBoundary: ", error, errorInfo);
	}

	componentDidCatch(error, info) {
		// Example "componentStack":
		//   in ComponentThatThrows (created by App)
		//   in ErrorBoundary (created by App)
		//   in div (created by App)
		//   in App
		this.logErrorService(error, info.componentStack);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return this.props.fallback;
		}

		return this.props.children;
	}
}
