import { isValidPhoneNumber } from "react-phone-number-input";

export const emailValidationRegex = /^\w+([\+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;

export const getStorageUrl = (shortPath): string => {
	return process.env.REACT_APP_STORAGE_URL + shortPath;
};

export const formatMoney = (amount: number, convertToDollar: boolean = false, roundPlaces: number = 2): string => {
	if (convertToDollar) amount = convertAmountToDollar(amount);
	try {
		const ret: string = `$${amount.toFixed(roundPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
		return ret;
	} catch (e) {
		return amount.toString();
	}
};

export const convertAmountToDollar = (amount: number): number => {
	return amount / 100;
}

export const formatPercent = (decimalPercent, addTag: boolean = true) => {
	const formatted = (decimalPercent * 100).toFixed(2);
	return addTag ? `${formatted}%` : decimalPercent;
};

export const formatDbDate = (dateString, withTime: boolean = false): string => {
	if (dateString == null || dateString == "undefined") return "";
	const halves = dateString.split("T");
	const justDate = halves[0];
	var pieces = justDate.split("-");
	var ret = [pieces[1], pieces[2], pieces[0]].join("/");
	if (withTime && halves[1]) ret += " " + halves[1];
	return ret;
};

export const toDbDate = (dateString): string => {
	var pieces = dateString.split("/");
	var ret = [pieces[2], pieces[0], pieces[1]].join("-");
	return ret;
};

export const capitalizeFirstLetter = (word: string): string => {
	return word.charAt(0).toUpperCase() + word.slice(1);
};

export const randomFromArray = (arr): any => {
	return arr[Math.floor(Math.random() * arr.length)];
};

export const getDomain = (): string => {
	const urlParts = window.location.host.split(".");
	const domainIndex = urlParts.length > 1 ? 1 : 0;
	//handle localhost case by stripping off any port information
	if (urlParts[domainIndex].toLowerCase().includes("localhost")) urlParts[domainIndex] = "localhost";
	return urlParts.join(".");
};

export const validateEmail = (email: string): boolean => {
	if (emailValidationRegex.test(email)) {
		return true;
	}
	return false;
};

export const validatePhoneNumber = (phone: string): boolean => {
	if (!!!phone)
		//blank/null/undefined
		return true;
	return isValidPhoneNumber(phone);
};

export const getBaseUrl = (): string => {
	let base: string = window.location.protocol + "//" + window.location.hostname;
	if (window.location.port) base += ":" + window.location.port;
	return base;
};

export const getUrl = (): string => {
	let base: string = getBaseUrl();
	return base + getUrlPath();
};

export const getUrlPath = (): string => {
	return window.location.pathname;
};

export const getRedirectUrl = (path: string): string => {
	let base: string = getBaseUrl();
	return base + "/" + path;
};

export const getRedirectUrl2 = (): string => {
	let base: string = getBaseUrl();
	return base + "/redirect";
};

export const getQueryStringFromObject = (filterObject: object): string => {
	let filterString = "";
	for (const index in filterObject) {
		if (Array.isArray(filterObject[index])) filterObject[index].forEach(value => (filterString += "&" + index + "=" + value));
		else if (filterObject[index]) filterString += "&" + index + "=" + filterObject[index];
	}
	return filterString;
};
