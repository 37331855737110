import React from "react";
import Container from "react-bootstrap/Container";
import logo from "./img/aprann-logo.png";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import "./style.css";

export const LoadingMessage = () => {
	return (
		<Container className={"h-100 justify-content-center center-block splash-screen"}>
			<div style={{ textAlign: "center", padding: "10em" }}>
				<img src={logo} style={{ width: "300px" }} />
				<br />
				{/*One moment while we load your app.*/}
				<div className="mt-1">
					<h3>Generously Given. Relentlessly Pursued.</h3>
				</div>
				<LoadingIndicator />
			</div>
		</Container>
	);
};

export default LoadingMessage;
