//cause IE....
import "react-app-polyfill/ie11";
import "core-js/es/object";
import * as Sentry from "@sentry/browser";

// Importing the Bootstrap CSS
// import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import App from "./App";
import Onboard from "components/Admin/Integrations/IntegrationsOnboard";
import { getDomain } from "./helpers";

import * as serviceWorker from "./serviceWorker";

import { createStore } from "redux";
import rootReducer from "./reducers";

//https://sentry.io/ - Monitor browser errors in the field
//if (process.env.REACT_APP_BUILD_TYPE !== "development")
Sentry.init({
	dsn: "https://9906b623f5d84ba084d16a0e9c5f6c72@o392857.ingest.sentry.io/5241135",
	environment: process.env.REACT_APP_BUILD_TYPE
});

const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

//get subdomain
const domain = getDomain();

const getComponent = () => {
	switch (window.location.pathname) {
		case "/onboard":
			return <Onboard />;
	}
	return <App domain={domain} />;
};

ReactDOM.render(
	//window.location hack. Unfortunatley both auth0 and stripe use "code" and "state" query params for redirects
	//so, needed a way to get at the stripe onboard without loading auth0
	<Provider store={store}>{getComponent()}</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
