import UsersInterface from "../interfaces/UsersInterface";
import SponsoredModel from "./SponsoredModel";
import PartnerUserRolesInterface from "interfaces/PartnerUserRolesInterface";
import PartnerUserEntitiesInterface from "interfaces/PartnerUserEntitiesInterface";
import apiWrapper from "helpers/ApiWrapper";
import SponsoredsInterface from "interfaces/SponsoredsInterface";
import UserSponsoredsInterface from "interfaces/UserSponsoredsInterface";

export const roles = {
	Administrator: 2,
	Reporting: 3,
	Management: 4,
	Messages: 5,
	DonorManagement: 6
};

export class UserModel {
	state: UsersInterface;
	roles: Array<string> = [];
	tokenInfo: object = {};

	constructor(id: number | UsersInterface) {
		if (typeof id == "object") this.state = id;
		else throw new Error("Loading by ID not implemented yet");
	}

	public async fetchSponsoreds(contextPartnersId: number): Promise<UserSponsoredsInterface[]> {
		return new Promise((resolve, reject) => {
			apiWrapper(`users/${this.id}/sponsoreds`, {
				method: "get",
				authorized: true
			})
				.then(results => {
					resolve(results as UserSponsoredsInterface[]);
				})
				.catch(e => {
					throw e;
				});
		});
	}

	setRoles(roles: Array<string>) {
		this.roles = roles;
	}

	setTokenInfo(tokenInfo: object) {
		this.tokenInfo = tokenInfo;
	}

	statePropertyOrError(stateProperty: string): any {
		if (typeof this.state[stateProperty] !== "undefined") return this.state[stateProperty];
		throw new Error(`Error - Invalid user model state property ${stateProperty}`);
	}

	getId(): number {
		return this.statePropertyOrError("id");
	}
	get id() {
		return this.statePropertyOrError("id");
	}

	getFirstName(): string {
		return this.statePropertyOrError("firstName");
	}
	get firstName() {
		return this.statePropertyOrError("firstName");
	}

	getLastName(): string {
		return this.statePropertyOrError("lastName");
	}
	get lastName() {
		return this.statePropertyOrError("lastName");
	}

	getEmail(): string {
		return this.statePropertyOrError("email");
	}
	get email() {
		return this.statePropertyOrError("email");
	}
	get address1() {
		return this.statePropertyOrError("address1");
	}
	get address2() {
		return this.statePropertyOrError("address2");
	}
	get city() {
		return this.statePropertyOrError("city");
	}
	get stateCode() {
		return this.statePropertyOrError("state");
	}
	get zip() {
		return this.statePropertyOrError("zip");
	}
	get phone(): string | undefined {
		return this.state.phone;
	}
	get tempAccount(): boolean {
		return this.state.tempAccount ?? false;
	}
	get isAprannUser(): boolean {
		return this.hasRole(1, roles.Administrator);
	}

	getAvatar(): string {
		return this.tokenInfo["picture"];
	}

	getName(): string {
		return this.statePropertyOrError("firstName") + " " + this.statePropertyOrError("lastName");
	}

	hasRole(partnersId: number, rolesId: number): boolean {
		const roles = this.getPartnerRoles(partnersId);
		return Boolean(roles.find(e => e.rolesId === rolesId));
	}

	getPartnerEntities(partnersId: number): Array<PartnerUserEntitiesInterface> {
		if (this.state.partnerUsers) {
			const partnerUser = this.state.partnerUsers.find(pu => pu.partnersId === partnersId);
			if (partnerUser) return partnerUser.partnerUserEntities;
		}
		return [];
	}

	getPartnerRoles(partnersId: number): Array<PartnerUserRolesInterface> {
		if (this.state.partnerUsers) {
			const partnerUser = this.state.partnerUsers.find(pu => pu.partnersId === partnersId);
			if (partnerUser) return partnerUser.partnerUserRoles;
		}
		return [];
	}

	getAllAccessibleEntityIds(): Array<number> {
		let ret: Array<number> = [];
		if (this.state.partnerUsers) {
			this.state.partnerUsers.forEach(partnerUser => {
				ret = ret.concat(partnerUser.partnerUserEntities.map(entity => entity.entitiesId));
			});
		}
		return ret;
	}

	isEntityAccessible(entityId: number): boolean {
		return this.getAllAccessibleEntityIds().includes(entityId);
	}

	isAdminForSponsored(sponsored: SponsoredModel): boolean {
		return this.isEntityAccessible(sponsored.entitiesId);
	}

	//partner has admin access to any entities for partner
	isAdminForPartner(partnersId: number): boolean {
		return this.getPartnerEntityIds(partnersId).length > 0;
	}

	/**
	 * Get array of authorized entity id's for a given partner
	 */
	getPartnerEntityIds(partnersId: number): Array<number> {
		return this.getPartnerEntities(partnersId).map(e => e.entitiesId);
	}

	hasAnyPartnerPermission(partnersId: number): boolean {
		return this.getPartnerRoles(partnersId).length > 0;
	}

	hasPartnerEntityPermission(partnersId: number, entitiesId: number): boolean {
		const entities = this.getPartnerEntities(partnersId);
		return Boolean(entities.find(e => e.entitiesId == entitiesId));
	}

	hasSponsored(sponsoredsId: number): boolean {
		const userSponsoreds = this.statePropertyOrError("userSponsoreds");
		const found = userSponsoreds.find(element => element.sponsoredsId == sponsoredsId && element.dateEnded == null);
		return Boolean(found);
	}

	getActiveSponsoreds(): SponsoredsInterface[] {
		console.log("us", this.state);
		return this.state.userSponsoreds.map(us => us.sponsoreds);
	}
}

export default UserModel;
