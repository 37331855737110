export const SET_PARTNER = 'SET_PARTNER';
export const GET_PARTNER = 'GET_PARTNER';

export const setPartner = (partner) => {
    return {
        type: SET_PARTNER,
		payload: partner
    }
}

export const getPartner = () => {
    return {
        type: GET_PARTNER
    }
}
