import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Accordion, Card, Col, Alert } from "react-bootstrap";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import { apiWrapper } from "helpers/ApiWrapper";
import { getBaseUrl } from "helpers";
import { useAprannAuthUser } from "helpers/AprannUser";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

const UserSetup = ({ contextPartner }) => {
	const { auth0User, newUser, logout } = useAprannAuthUser();

	const [showUpdateModal, setShowUpdateModel] = useState<boolean>(false);

	//new update form elements
	const [firstName, setFirstName] = useState<string>();
	const [lastName, setLastName] = useState<string>();
	// const [email, setEmail] = useState<string>();
	const [address1, setAddress1] = useState<string>();
	const [address2, setAddress2] = useState<string>();
	const [city, setCity] = useState<string>();
	const [state, setState] = useState<string>();
	const [zip, setZip] = useState<string>();
	const [phone, setPhone] = useState<string>();

	//status
	const [error, setError] = useState<string>();
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (newUser) {
			setFirstName(auth0User["given_name"]);
			setLastName(auth0User["family_name"]);
			// setEmail(auth0User["email"]);
			setShowUpdateModel(true);
		}
	}, [newUser]);

	const onClickClose = () => {
		logout({ returnTo: getBaseUrl() });
		setShowUpdateModel(false);
	};

	const handleSubmit = event => {
		try {
			event.preventDefault();
			event.persist();

			if (phone && !isValidPhoneNumber(phone)) {
				window.alert("Invalid Phone Number: " + phone);
				return;
			}

			if (!!!auth0User["email"]) {
				window.alert(
					`There is not a valid email address associated with your account. Please contact ${contextPartner.supportEmail}`
				);
			}

			setLoading(true);
			apiWrapper(`users`, {
				method: "post",
				authorized: true,
				body: {
					firstName,
					lastName,
					email: auth0User["email"],
					address1,
					address2,
					city,
					state,
					zip,
					phone
				}
			})
				.then(results => {
					window.location.reload();
				})
				.catch(error => {
					setLoading(false);
					setError(error);
				});
		} catch (error) {
			console.log(error);
			setLoading(false);
			setError(error as string);
		}
	};

	return (
		<Modal show={showUpdateModal} size="lg" centered>
			<Form id="newUserForm" onSubmit={handleSubmit}>
				<Modal.Header>
					<Modal.Title>Welcome to {contextPartner.name}!</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{loading ? (
						<>
							<LoadingIndicator message="Almost there..." />
						</>
					) : (
						<>
							<h5>Just a couple more things, and you're on the way!</h5>
							<Form.Row>
								<Col>
									<Form.Group controlId="formFirstName">
										<Form.Label>First Name</Form.Label>
										<Form.Control
											type="text"
											placeholder="First Name"
											defaultValue={firstName}
											onChange={e => setFirstName(e.target.value)}
											required
										/>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group controlId="formLastName">
										<Form.Label>Last Name</Form.Label>
										<Form.Control
											type="text"
											placeholder="Last Name"
											defaultValue={lastName}
											onChange={e => setLastName(e.target.value)}
											required
										/>
									</Form.Group>
								</Col>
							</Form.Row>
							{/* <Form.Row>
								<Col>
									<Alert variant="info">
										Editing this email address does not change the email that you use to login. It will only affect the
										email address that communications are sent to.
									</Alert>
									<Form.Group controlId="formEmail">
										<Form.Label>Email address</Form.Label>
										<Form.Control
											type="email"
											placeholder="Enter email"
											defaultValue={email}
											onChange={e => setEmail(e.target.value)}
											required
										/>
										<Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text>
									</Form.Group>
								</Col>{" "}
							</Form.Row>*/}
							<hr />
							{!contextPartner.isSupportSite && (
								<>
									<Form.Row>
										<Col>
											<Form.Group controlId="address1">
												<Form.Label>Address</Form.Label>
												<Form.Control
													type="text"
													placeholder="Address"
													defaultValue={address1}
													onChange={e => setAddress1(e.target.value)}
													required
												/>
											</Form.Group>
										</Col>
									</Form.Row>
									<Form.Row>
										<Col>
											<Form.Group controlId="address2">
												<Form.Label>Address Line 2</Form.Label>
												<Form.Control
													type="text"
													defaultValue={address2}
													onChange={e => setAddress2(e.target.value)}
												/>
											</Form.Group>
										</Col>
									</Form.Row>
									<Form.Row>
										<Col>
											<Form.Group controlId="city">
												<Form.Label>City</Form.Label>
												<Form.Control
													type="text"
													placeholder="City"
													defaultValue={city}
													onChange={e => setCity(e.target.value)}
													required
												/>
											</Form.Group>
										</Col>
										<Col>
											<Form.Group controlId="state">
												<Form.Label>State / Province</Form.Label>
												<Form.Control
													type="text"
													placeholder="State"
													defaultValue={state}
													onChange={e => setState(e.target.value)}
													required
												/>
											</Form.Group>
										</Col>
										<Col>
											<Form.Group controlId="zip">
												<Form.Label>Postal / Zip Code</Form.Label>
												<Form.Control
													name="zip"
													type="text"
													placeholder="Zip"
													defaultValue={zip}
													onChange={e => setZip(e.target.value)}
													required
												/>
											</Form.Group>
										</Col>
									</Form.Row>
									<hr />
								</>
							)}
							<Form.Row>
								<Col>
									<Form.Group controlId="phone">
										<Form.Label>Mobile Phone Number (optional)</Form.Label>
										<PhoneInput
											placeholder="Enter phone number"
											value={phone}
											onChange={e => setPhone(e?.toString())}
											defaultCountry="US"
											style={{ maxWidth: "300px" }}
										/>
										<Form.Text className="text-muted">
											We'll never share your phone number with anyone else. This may be used to send occasional text
											message updates regarding your sponsorships, but you may opt out at any time.
										</Form.Text>
									</Form.Group>
								</Col>
							</Form.Row>
							{error && (
								<Alert variant="danger">
									An error occured. Please send the following information to support@aprann.org
									<br />
									{error}
								</Alert>
							)}
							<Accordion className="m-0 p-0">
								<Card>
									<Accordion.Toggle as={Card.Header} eventKey="0" style={{ textAlign: "center", border: "none" }}>
										<small className="text-muted">
											You agree to our{" "}
											<a href="https://outgiven.org/user-tos.html" target="_blank" rel="noreferrer">
												Terms of Service
											</a>
											. <br />
											You are protected by our{" "}
											<a href="https://outgiven.org/privacy.html" target="_blank" rel="noreferrer">
												Privacy Policy
											</a>
										</small>
									</Accordion.Toggle>
								</Card>
							</Accordion>
						</>
					)}
				</Modal.Body>
				<Modal.Footer className="mt-0">
					{!loading && (
						<Button type="submit" variant="primary">
							Save
						</Button>
					)}

					<Button variant="secondary" onClick={() => onClickClose()}>
						Log Out
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default UserSetup;
