class StorageContainer
{
	constructor(container){
		this.storage = window.localStorage;
		this.container = container;
	}

	getStoredContainer = () => {
		let containerObj = this.storage.getItem(this.container);
		return containerObj !== null ? JSON.parse(containerObj) : {};
	}

	setStoredContainer = (containerObj) => {
		this.storage.setItem(this.container, JSON.stringify(containerObj));
	}

	addObject = function(key, newObj)  {
		let container = this.getStoredContainer();
		container[key] = newObj;
		this.setStoredContainer(container);
	};

	getObject = (key) => {
		let container = this.getStoredContainer();
		return container[key];
	};

	hasObject= (key) => {
		let container = this.getStoredContainer();
		return key in container;
	};

	removeObject = (key) => {
		let container = this.getStoredContainer();
		delete container[key];
		this.setStoredContainer(container);
	};

	removeObjects = (keys) => {
		keys.forEach((key) => {
			this.removeObject(key);
		});
	};
}

export default StorageContainer;