import createAuth0Client from "@auth0/auth0-spa-js";

let _partnersId: number | undefined = undefined;
let _auth0clientId: string = "";
export const setAuth0ClientId = (id: string) => {
	_auth0clientId = id;
};

export const configureApi = (partnersId: number, auth0ClientId: string) => {
	_partnersId = partnersId;
	_auth0clientId = auth0ClientId;
};

export interface ApiWrapperFilterObject {
	name: string;
	value: string;
}

interface ApiParameters {
	method: string;
	authorized: boolean;
	authorizedOptional?: boolean;
	authToken?: string;
	body?: any;
	filters?: Array<ApiWrapperFilterObject>;
	detailedResults?: boolean;
	contentType?: string;
	acceptContentType?: string;
	jsonBody?: boolean;
	fileName?: string;
}

/**
 * Converts a key=>value object representation of filters into an array of ApiWrapperFilterObjects,
 * where each entry is it's own individual mapping.
 * @param filters
 */
export const convertFilters = (filters: object): ApiWrapperFilterObject[] => {
	const filterObjects: ApiWrapperFilterObject[] = [];
	for (const index in filters) {
		if (Array.isArray(filters[index])) filters[index].forEach(value => filterObjects.push({ name: index, value }));
		else filterObjects.push({ name: index, value: filters[index] ?? "" });
	}
	return filterObjects;
};

export const apiWrapper = async (path: string, parameters: ApiParameters): Promise<object> => {
	let auth0Client;

	const jsonBody = parameters.jsonBody ?? true;

	return new Promise(async (resolve, reject) => {
		const fetchParams: RequestInit = {
			method: parameters.method,
			body: jsonBody ? JSON.stringify(parameters.body) : parameters.body,
			headers: {
				Accept: parameters.acceptContentType ?? "application/json"
			}
		};

		if (fetchParams.headers !== undefined) {
			//otherwise typescript complains
			if (parameters.contentType === undefined) fetchParams.headers["Content-Type"] = "application/json";
			//very important to not set content-type at all in the case where we explicitly set to blank (for multi-part form data)
			else if (parameters.contentType !== "") fetchParams.headers["Content-Type"] = parameters.contentType;
		}

		if (parameters.authorized) {
			if (parameters.authToken) {
				if (fetchParams.headers) fetchParams.headers["Authorization"] = `Bearer ${parameters.authToken}`;
			} else {
				if (!auth0Client)
					auth0Client = await createAuth0Client({
						domain: process.env.REACT_APP_AUTH0_DOMAIN ?? "",
						client_id: _auth0clientId,
						audience: process.env.REACT_APP_AUTH0_AUDIENCE,
						//https://community.auth0.com/t/call-to-authorize-fails-on-safari/43477/8
						useRefreshTokens: true,
						cacheLocation: "localstorage"
					});
				if (auth0Client.isAuthenticated())
					await auth0Client
						.getTokenSilently()
						.then(token => {
							if (fetchParams.headers) fetchParams.headers["Authorization"] = `Bearer ${token}`;
						})
						.catch(error => {
							if (!parameters.authorizedOptional)
								//not set or explicitly false
								//this needs to be made more robust to check for type of error.
								throw error;
						});
			}
		}

		let url = process.env.REACT_APP_API_URL + path;

		//always include ?partnersId=x
		const defaultFilters: ApiWrapperFilterObject[] = [{ name: "partnersId", value: _partnersId?.toString() ?? "" }];
		const filters = defaultFilters.concat(parameters.filters ?? []);

		url += "?" + filters.map(filter => `${filter.name}=${filter.value}`).join("&");

		fetch(url, fetchParams)
			.then(async res => {
				if (!res.ok) {
					const errorMessage = await res.text(); //.then(text => text ? text : res.status.toString());
					throw errorMessage;
				}
				const contentType = res.headers.get("content-type");

				//we are dealing with a json response object
				if (contentType && contentType.indexOf("application/json") !== -1) {
					if (!parameters.detailedResults) {
						//no extra manipulation, just return json object as is
						return res.json();
					} else {
						//add some detail by waiting for json results promise and tacking on extra info
						return res.json().then(json => ({
							items: json,
							count: res.headers.get("x-total-count")
						}));
					}
				} else if (contentType && contentType.indexOf("application/pdf") !== -1) {
					return res
						.blob()
						.then(blob => window.URL.createObjectURL(blob))
						.then(url => {
							const tempLink = document.createElement("a");
							tempLink.href = url;
							tempLink.setAttribute("download", parameters.fileName ?? "message.pdf");
							tempLink.click();
						});
				} else if (contentType && contentType.indexOf("video") !== -1) {
					return res.blob()
				} else return res.text;
			})
			.then(data => {
				resolve(data);
			})
			.catch(error => {
				console.log("API Error: " + error);
				reject(error);
			});
	});
};

export default apiWrapper;
