import FilterTypesInterface from "./FilterTypesInterface";
import PartnerSponsoredTypePlansInterface from "./PartnerSponsoredTypePlansInterface";

export enum SponsoredTypeIds {
	Student = 1,
	Teacher = 2,
	Employee = 3,
	People = 4,
	Classroom = 5,
	Animal = 6,
	Project = 7,
}

export const AllSponsoredTypes: number[] = [
	SponsoredTypeIds.Student,
	SponsoredTypeIds.Teacher,
	SponsoredTypeIds.Employee,
	SponsoredTypeIds.People,
	SponsoredTypeIds.Classroom,
	SponsoredTypeIds.Animal,
	SponsoredTypeIds.Project
];

export enum GridDisplayTypes {
	Columns = "column",
	Deck = "deck",
	DeckCropped = "deck-cropped"
}

export interface GridDisplayInterface {
	type: GridDisplayTypes;
	description: string;
}

export const GridDisplayOptions: GridDisplayInterface[] = [
	{ type: GridDisplayTypes.Deck, description: "Deck - Traditional grid with equal height rows." },
	{ type: GridDisplayTypes.DeckCropped, description: "Deck cropped - Traditional grid with images automatically cropped to square." },
	{ type: GridDisplayTypes.Columns, description: "Column - Masonry style grid, similar to a Pinterest board layout." },
]

export interface PartnerSponsoredTypesInterface {
	id?: number;
	partnersId: number;
	sponsoredTypesId: number;
	customLabelSingular: string;
	customLabelPlural: string;
	description: string;
	maxSlots: number;
	showSlots: boolean;
	visibleSlots: number;
	customSlotName?: string;
	currentSponsoredTypePlansId?: number;
	currentSponsoredTypePlans?: PartnerSponsoredTypePlansInterface;
	sponsoredTypes?: SponsoredTypesInterface;
	maskProfiles: boolean;
	showBirthday: boolean;
	showAge: boolean;
	showLastName: boolean;
	showPublicBio: boolean;
	sponsoredTypePlans?: PartnerSponsoredTypePlansInterface[];
	gridLayout: string;
	identifier: string;
	customColumns?: CustomColumnsInterface[];
	active: boolean;
	showFullySponsored: boolean;
}

export interface SponsoredTypesInterface {
	description: string;
	icon: string;
	id: number;
	labelPlural: string;
	labelSingular: string;
	sponsoredTypeFilterTypes: SponsoredTypeFilterTypesInterface[];
}

export interface SponsoredTypeFilterTypesInterface {
	filterTypes: FilterTypesInterface;
	sponsoredTypesId: number;
	id: number;
}

export interface CustomColumnsInterface {
	id: number;
	columnName: string;
	columnDataType: string;
	columnSequence: number;
	public: boolean;
}

export interface CustomColumnValuesInterface {
	customColumnsId: number;
	columnValue: string;
	columnName?: string;
	sponsoredsId: number;
}
