import CartItemsInterface from "interfaces/CartItemsInterface";

export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const EMPTY_CART = "EMPTY_CART";

//add cart action
export const addCartItem = (cartItem: CartItemsInterface) => {
	return {
		type: ADD_TO_CART,
		payload: cartItem
	};
};
//remove item action
export const removeCartItem = id => {
	return {
		type: REMOVE_ITEM,
		payload: id
	};
};

//remove all items action
export const emptyCart = () => {
	return {
		type: EMPTY_CART
	};
};
