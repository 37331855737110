import GradesInterface from "../interfaces/GradesInterface";

export const grades: Array<GradesInterface> = [
	{
		id: 0,
		value: "Unknown"
	},
	{
		id: 1,
		value: "Nursery School"
	},
	{
		id: 2,
		value: "Preschool"
	},
	{
		id: 3,
		value: "Kindergarten"
	},
	{
		id: 4,
		value: "1st grade"
	},
	{
		id: 5,
		value: "2nd grade"
	},
	{
		id: 6,
		value: "3rd grade"
	},
	{
		id: 7,
		value: "4th grade"
	},
	{
		id: 8,
		value: "5th grade"
	},
	{
		id: 9,
		value: "6th grade"
	},
	{
		id: 10,
		value: "7th grade"
	},
	{
		id: 11,
		value: "8th grade"
	},
	{
		id: 12,
		value: "9th grade"
	},
	{
		id: 13,
		value: "10th grade"
	},
	{
		id: 14,
		value: "11th grade"
	},
	{
		id: 15,
		value: "12th grade"
	},
	{
		id: 27,
		value: "13th grade"
	},
	{
		id: 17,
		value: "Special Education"
	},
	{
		id: 18,
		value: "Trade School"
	},
	{
		id: 16,
		value: "University - General"
	},
	{
		id: 19,
		value: "University - 1st Year"
	},
	{
		id: 20,
		value: "University - 2nd Year"
	},
	{
		id: 21,
		value: "University - 3rd Year"
	},
	{
		id: 22,
		value: "University - 4th Year"
	},
	{
		id: 23,
		value: "University - 5th Year"
	},
	{
		id: 24,
		value: "University - Grad School"
	},
	{
		id: 25,
		value: "Internship"
	},
	{
		id: 26,
		value: "Not Attending"
	},
];

export class GradeModel {
	static getGradeById(gradesId: number) {
		const find: GradesInterface | undefined = grades.find(element => element.id === gradesId);
		if (!find) throw new Error(`${gradesId} is not a valid grade ID`);
		return find;
	}
}
