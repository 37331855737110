import React, { useEffect, useState } from "react";
import { Container, Alert } from "react-bootstrap";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import queryString from "query-string";
import { apiWrapper } from "helpers/ApiWrapper";

const IntegrationsOnboard = () => {
	const [loaded, setLoaded] = useState(false);
	const [resultMsg, setResultMsg] = useState("Invalid onboarding sequence");

	useEffect(() => {
		const values = queryString.parse(window.location.search);
		if (values.code && values.state) {
			//this is a stripe onboarding callback, need to register with api and then refresh
			apiWrapper(`partners/oauthonboard`, {
				method: "post",
				authorized: false,
				body: {
					state: values.state,
					authorizationCode: values.code
				}
			})
				.then(results => {
					setResultMsg(`Please wait while we redirect you to ${results["redirectUrl"]}.`);
					window.location.href = results["redirectUrl"];
				})
				.catch(error => {
					console.log(`Failed onboarding: ${error}`);
					setResultMsg(`Failed onboarding: ${error}. Please alert Aprann support.`);
					setLoaded(true);
					//window.location.href = window.location.href.split("?")[0];
				});
		} else setLoaded(true);
	}, []);
	if (!loaded) return <LoadingIndicator message="Completing onboarding. Please wait to be redirected." />;
	else
		return (
			<Container className="mt-5">
				<Alert variant="info">{resultMsg}</Alert>
			</Container>
		);
};
export default IntegrationsOnboard;
